import React from 'react'
import Header from './Header'
import Tabs from './Tabs'

export default function Home() {
  return (
    <>
    <Header/>
    <Tabs/>
    </>
  )
}
