import React from "react";
import { CSVLink } from "react-csv";
import {AiOutlineSearch} from 'react-icons/ai'

export default function TableHeader({props}) {
    return (
        <>
            <div className='d-flex justify-content-between mt-2 px-3 py-1'>
                <div className="w-50 form-control me-3" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <input type="search" placeholder="Quick Search" className=" w-100 inputFieldOut" onChange={(e) => props.setSearch(e.target.value)} style={{border:'none'}}/>
                    <span style={{textAlign:'center'}}><AiOutlineSearch size={20}/></span>
                </div>
                <div>
                    <CSVLink data={props?.selectedProjects} filename={"Effectual-RMS-Projects.csv"} className="btn btn-success text-light me-3 px-4" type='button' style={{width:'12rem'}}>Bibliography</CSVLink>
                    <CSVLink data={props?.projects} filename={"Effectual-RMS-Projects.csv"} className="btn theme-bg text-light px-4" type='button' style={{width:'12rem'}}>Bibliography All</CSVLink>
                </div>
            </div>
        </>
    )
}
