import Header from "../main/Header";
import CommentInbox from "./Comments/CommentInbox";
import CreateProjectUsingDraft from "../Forms/CreateProjectUsingDraft";
import FileManger from "./FileManger";
import Feedback from "./Feedback";
import Evaluation from "./Evaluation";
import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { useEffect } from "react";
import { UserContext } from "../contexts/UserContext";

export default function SelectedDraft() {
  const { draftId, setDraftId } = useContext(ProjectContext);
  //   const { user } = useContext(UserContext);

  return (
    <>
      <Header />
      <div className="container" style={{ paddingTop: "4rem" }}>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link fw-bold active"
              data-bs-toggle="tab"
              data-bs-target="#comment"
              type="button"
              role="tab"
              aria-controls="comment"
              aria-selected="true"
            >
              Draft Details
            </button>
          </li>
        </ul>
        <div className="tab-content bg-white" id="myTabContent">
          <div className="tab-pane show active" id="comment" role="tabpanel">
            <CreateProjectUsingDraft />
          </div>
        </div>
      </div>
    </>
  );
}
