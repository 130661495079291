import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/main/Home";
import SelectedProject from "./components/SelectedProject/SelectedProject";
import WriteComment from "./components/SelectedProject/Comments/WriteComment";
import { ProjectContext } from "./components/contexts/ProjectContext";
import { useState, useEffect } from "react";
import Landing from "./components/landing/Landing";
import { UserContext } from "./components/contexts/UserContext";
import PasswordReset from "./components/landing/PasswordReset";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SelectedDraft from "./components/SelectedProject/SelectedDraft";

function App() {
  const [projectId, setProjectId] = useState(null);
  const [user, setUser] = useState({ userData: "", auth: false, token: null });
  const [replyTo, setReplyTo] = useState(null);
  const [isProjectAddOrUpdate, setIsProjectAddOrUpdate] = useState(false);
  const [isDraftAdd, setIsDraftAdd] = useState(false);
  const [draftId, setDraftId] = useState(null);

  useEffect(() => {
    if (user?.auth) {
      setUser((user) => ({
        ...user,
        auth: true,
        userData: JSON.parse(localStorage.getItem("userData")),
        token: JSON.parse(localStorage.getItem("token")),
      }));
    } else if (localStorage.getItem("userData")) {
      setUser((user) => ({
        ...user,
        auth: true,
        userData: JSON.parse(localStorage.getItem("userData")),
        token: JSON.parse(localStorage.getItem("token")),
      }));
    }
  }, [user?.auth]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <UserContext.Provider value={{ user, setUser }}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/rms"  element={<Navigate to="/" replace />}/>

          {user?.auth && user?.userData && (
            <>
              <Route
                path="/main"
                element={
                  <ProjectContext.Provider
                    value={{
                      projectId,
                      setProjectId,
                      isProjectAddOrUpdate,
                      setIsProjectAddOrUpdate,
                      setDraftId,
                      draftId,
                      setIsDraftAdd,
                      isDraftAdd,
                    }}
                  >
                    <Home setProjectId={setProjectId} setDraftId={setDraftId} />
                  </ProjectContext.Provider>
                }
              />

              <Route
                path="/project"
                element={
                  <ProjectContext.Provider
                    value={{
                      projectId,
                      setProjectId,
                      setReplyTo,
                      setDraftId,
                      setIsProjectAddOrUpdate,
                    }}
                  >
                    <SelectedProject
                      projectId={projectId}
                      setIsProjectAddOrUpdate={setIsProjectAddOrUpdate}
                      setReplyTo={setReplyTo}
                      setDraftId={setDraftId}
                    />
                  </ProjectContext.Provider>
                }
              />

              <Route
                path="/draft"
                element={
                  <ProjectContext.Provider
                    value={{
                      draftId,
                      setDraftId,
                      setIsProjectAddOrUpdate,
                      setIsDraftAdd,
                      isDraftAdd,
                    }}
                  >
                    <SelectedDraft
                      draftId={draftId}
                      setDraftId={setDraftId}
                      setIsProjectAddOrUpdate={setIsProjectAddOrUpdate}
                      setIsDraftAdd={setIsDraftAdd}
                      isDraftAdd={isDraftAdd}
                    />
                  </ProjectContext.Provider>
                }
              />

              <Route
                path="/comment"
                element={
                  <ProjectContext.Provider
                    value={{ projectId, setProjectId, replyTo }}
                  >
                    <WriteComment projectId={projectId} replyTo={replyTo} />
                  </ProjectContext.Provider>
                }
              />
            </>
          )}

          <Route
            path="/passwordReset/:token/:userId"
            element={<PasswordReset />}
          />

          <Route
            path="*"
            element={
              <>
                <h1 className="bg-danger text-white text-center mt-0">
                  404 : Page Not Found
                </h1>
              </>
            }
          />
        </Routes>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
