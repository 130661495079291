import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import TableHeader from "./TableHeader";
import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { UserContext } from "../contexts/UserContext";
import Moment from "react-moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

export default function AllProjects() {
  const [search, setSearch] = useState("");
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const { setProjectId, isProjectAddOrUpdate, setIsProjectAddOrUpdate } =
    useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  //setProjectId null when user press back button to main panel,
  // which helps to create new project instead to update selected project.
  useEffect(() => {
    if (location.pathname === "/main") {
      setProjectId(null);
    }
  }, [location.pathname, setProjectId]);

  // settingProject id in state as well as local storage so that we can persist our
  // state after refreshing in selected project components
  const navigate = useNavigate();
  const setProjectIdHandler = (projectId) => {
    setProjectId(projectId);
    window.localStorage.setItem("projectId", `${projectId}`);
    navigate("/project");
  };

  //Edit Project

  //loading all project list once only
  useEffect(() => {
    //fetching data from endpoint

    getProjects();
  }, [isProjectAddOrUpdate, user?.userData]);

  const getProjects = async () => {
    try {
      setLoading(true);
      let response;
      if (
        user?.userData?.role === "Technical Expert" ||
        user?.userData?.role === "Patent Expert"
      ) {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/projects/getProjectsAssignedToUser/${user?.userData?._id}`
        );
      } else {
        response = await axios.get(`${process.env.REACT_APP_API_URL}/projects`);
      }
      setProjects(response.data);
      setFilteredProjects(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  //multiple fields search based on search key
  useEffect(() => {
    const filters = projects.filter(
      (project) =>
        JSON.stringify(project).toLowerCase().indexOf(search.toLowerCase()) !==
        -1
    );

    setFilteredProjects(filters);
  }, [projects, search]);

  const terminateProject = async (projectId) => {
    try {
      const { value } = await Swal.fire({
        title: "Are you sure?",
        text: `You want to terminate the project ${projectId}.`,
        icon: "warning",
        confirmButtonText: "Terminate",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      });
      if (value) {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/projects/terminateProjectStatus/${projectId}`
          )
          .then((res) => {
            Swal.fire({
              title: "Done!",
              text: "Project has been terminated successfully.",
              icon: "success",
              timer: 2000,
              button: false,
            });
            getProjects();
          });
        //to recall projects
        setIsProjectAddOrUpdate(true);
      } else {
        Swal.fire({ text: "Project is safe from termination.", icon: "info" });
      }
    } catch (error) {
      toast.error("Somthing went wrong. Project has not terminated.");
    }
  };

  const completeProject = async (projectId) => {
    try {
      const { value } = await Swal.fire({
        title: "Are you sure?",
        text: `You want to complete the project ${projectId}.`,
        // icon: "warning",
        confirmButtonText: "Complete",
        confirmButtonColor: "green",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      });
      if (value) {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/files/updateStatus/${projectId}`,
            { status: "Completed" }
          )
          .then((res) => {
            getProjects();
            Swal.fire({
              title: "Done!",
              text: "Project has been completed .",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
        //to recall projects
        setIsProjectAddOrUpdate(true);
      } else {
        Swal.fire({ text: "Project is still in progress .", icon: "info" });
      }
    } catch (error) {
      toast.error("Somthing went wrong. Project has not been completed.");
    } finally {
      getProjects();
    }
  };

  const columns = [
    {
      name: "Project Id",
      selector: (row) => row.projectId,
      sortable: true,
    },

    {
      name: "Search Object",
      selector: (row) => row.searchObject,
      sortable: true,
    },
    // {
    //   name: "Type",
    //   selector: (row) => row.searchObject,
    //   sortable: true,
    // },
    {
      name: "Type",
      selector: (row) => 'Invalidity Search',
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row?.projectManager,
      sortable: true,
    },

    {
      name: "Request Date",
      selector: (row) => row?.requestedDate,
      sortable: true,
      cell: (row) => <Moment format="DD/MM/YYYY">{row?.requestedDate}</Moment>,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Interim Report" ? (
          <span
            className="badge rounded-pill   text-primary"
            style={{
              fontSize: "14px",
              width: "90px",
              border: "2px solid blue",
            }}
          >
            Interim
          </span>
        ) : row.status === "Progress" ? (
          <span
            className="badge rounded-pill  text-warning "
            style={{
              fontSize: "14px",
              width: "90px",
              border: "2px solid #FDCB94",
            }}
          >
            Progress
          </span>
        ) : row.status === "Completed" ? (
          <span
            className="badge rounded-pill text-success "
            style={{
              fontSize: "14px",
              width: "90px",
              border: "2px solid green",
            }}
          >
            Completed
          </span>
        ) : row?.status === "Terminated" ? (
          <span
            className="badge rounded-pill text-secondary  border border-5-secondry"
            style={{
              fontSize: "14px",
              width: "90px",
              border: "2px solid green",
            }}
          >
            Terminated
          </span>
        ) : (
          row?.status
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn text-danger btn-sm"
            onClick={() => terminateProject(row.projectId)}
          >
            <MdDeleteOutline size={20} />
          </button>

          {user?.userData?.role == "Effectual Admin" ||
          user?.userData?.role == "Manager" ||
          user?.userData?.role == "Searcher" ? (
            <button onClick={() => completeProject(row.projectId)}>
              <img src="/tick.png" alt="" style={{ width: "20px" }} />
            </button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        fontSize: "12px",
        fontWeight: "bolder",
        cursor: "pointer",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  // const conditionalRowStyles = [
  //     {
  //         when: row => row?.status?.includes("2"),
  //         style: {
  //             backgroundColor: '#04ff1857',
  //             color: 'black'
  //         },
  //     }
  // ]

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <DataTable
          columns={columns}
          data={filteredProjects}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="470px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            <TableHeader props={{ setSearch, projects, selectedProjects }} />
          }
          onRowClicked={(row) => setProjectIdHandler(row?.projectId)}
          striped
          customStyles={customStyles}
          responsive
          onSelectedRowsChange={(selectedRows) =>
            setSelectedProjects(selectedRows?.selectedRows)
          }
          progressPending={loading}
          progressComponent={
            <div className="d-flex align-items-center p-5">
              <div
                className="spinner-border text-primary"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="sr-only mt-5"></span>
              </div>
              <h5 className="color text-secondary ms-3">Loading Projects...</h5>
            </div>
          }
        />
      </div>
    </>
  );
}
