import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import axios from "axios";
import { BiDownload } from "react-icons/bi";
import Moment from "react-moment";
import { FiUpload } from "react-icons/fi";
import ReportUpload from "./ReportUpload";
import { UserContext } from "../contexts/UserContext";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function FileManger() {
  const { user } = useContext(UserContext);
  const [effectualReports, setEffectualReports] = useState([]);
  const [clientReports, setClientReports] = useState([]);
  const { setProjectId, projectId } = useContext(ProjectContext);
  const [showModel, setShowModel] = useState(true);
  const [reloadFile, setReloadFile] = useState(false);

  useEffect(() => {
    if (!projectId) setProjectId(window?.localStorage?.getItem("projectId"));
  }, [projectId, setProjectId]);

  const getEffectualReports = async () => {
    try {
      const info = await axios.get(
        `${process.env.REACT_APP_API_URL}/commentFiles/${projectId}`
      );
      setEffectualReports(info?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEffectualReports();
  }, [projectId, showModel, reloadFile]);

  const getClientReports = async () => {
    try {
      const info = await axios.get(
        `${process.env.REACT_APP_API_URL}/commentFiles/client/${projectId}`
      );
      setClientReports(info?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClientReports();
  }, [projectId]);

  const deleteFile = async (projectId, fileId) => {
    try {
      const { value } = await Swal.fire({
        // title: "You want to Delete this file?",
        text: `You want to Delete this file?`,
        icon: "warning",
        confirmButtonText: "Delete",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      });

      if (value) {
        const info = await axios.delete(
          `${process.env.REACT_APP_API_URL}/commentFiles/client/deleteFile?projectId=${projectId}&fileId=${fileId}`
        );
        Swal.fire({
          title: "Done!",
          text: "File  has been Deleted Successfull.",
          icon: "success",
          timer: 300,
          button: false,
        });
        getClientReports();
      } else {
        Swal.fire({ text: "File is safe from Delete.", icon: "info" });
      }
    } catch (error) {
      toast.error("File Deleted not Successfully!");

      console.log(error);
    }
  };

  const deleteEffectualFiles = async (projectId, fileId) => {
    try {
      const { value } = await Swal.fire({
        // title: "You want to Delete this file?",
        text: `You want to Delete this file?`,
        icon: "warning",
        confirmButtonText: "Delete",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      });
      if (value) {
        const info = await axios.delete(
          `${process.env.REACT_APP_API_URL}/commentFiles/effectual/deleteFile?projectId=${projectId}&fileId=${fileId}`
        );
        toast.success("File Deleted Sucessfully");
        getEffectualReports();
      }
    } catch (error) {
      toast.error("File Deleted not Successfully!");
      console.log(error);
    }
  };
  return (
    <>
      <div className="container">
        <div className="row row-cols-md-2 pt-3 ">
          <div className="mt-2">
            <h5 className="text-center theme-color fw-bold">Client Files</h5>
            <div style={{ height: "70vh" }} className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead className="table-dark">
                  <tr style={{ fontSize: "14px" }}>
                    <th>File</th>
                    <th>Uploaded By</th>
                    <th>Uploaded On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clientReports?.map?.((item, a) =>
                    item?.files?.map((url, b) => (
                      <tr key={`${a}${b}`}>
                        <td
                          style={{
                            width: "17px",
                            height: "5px",
                            cursor: "pointer",
                          }}
                          title={url.label || url.filename}
                        >
                          {(url.label || url.filename)?.slice(0, 6)}..
                        </td>
                        <td>{item.uploadedBy}</td>
                        <td>
                          <Moment format="DD/MM/YYYY HH:mm ">
                            {item?.createdAt }
                          </Moment>
                        </td>
                        <td className="text-center">
                          <a
                            href={
                              url.hasOwnProperty("url") ? url.url : url.path
                            }
                            className="me-3 fw-bold lh-1"
                          >
                            <BiDownload size={25} />
                          </a>
                          <a
                            onClick={() => deleteFile(item?._id, b)}
                            className="me-3 fw-bold  lh-1"
                            style={{ color: "#e02f35" }}
                          >
                            <MdDelete size={25} />
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="theme-color fw-bold d-inline-block">
                Effectual Files
              </h5>
              {user?.userData?.role === "Effectual Admin" ||
              user?.userData?.role === "Manager" ? (
                <button
                  type="button"
                  className="btn btn-primary mb-2 ms-3 btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#reportUpload"
                >
                  <FiUpload /> Report Status
                </button>
              ) : (
                ""
              )}
            </div>
            <div style={{ height: "70vh" }} className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead className="table-dark">
                  <tr style={{ fontSize: "14px" }}>
                    <th>File</th>
                    <th>Uploaded By</th>
                    <th>Uploaded On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {effectualReports?.map?.((item, a) =>
                    item?.files?.map((url, b) =>
                      url !== "" ? (
                        <tr key={`${a}${b}`}>
                          <td
                            style={{ width: "13px" }}
                            title={url.label || url.filename}
                          >
                            {" "}
                            {(url.label || url.filename)?.slice(0, 6)}..
                          </td>
                          <td>{item.uploadedBy}</td>
                          <td>
                            <Moment format="DD/MM/YYYY HH:mm ">
                              {item.createdAt}
                            </Moment>
                          </td>
                          <td className="text-center">
                            <a
                              href={
                                url.hasOwnProperty("url") ? url.url : url.path
                              }
                              className="me-3 fw-bold lh-1"
                            >
                              <BiDownload size={25} />
                            </a>
                            <a
                              onClick={() => deleteEffectualFiles(item?._id, b)}
                              className="me-3 fw-bold lh-1"
                              style={{ color: "red" }}
                            >
                              <MdDelete size={25} />
                            </a>
                          </td>
                        </tr>
                      ) : null
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {(user?.userData?.role === "Effectual Admin" ||
        user?.userData?.role === "Manager") &&
      showModel ? (
        <ReportUpload
          setShowModel={setShowModel}
          showModel={showModel}
          getClientReports={getClientReports}
          getEffectualReports={getEffectualReports}
          setReloadFile={setReloadFile}
          reloadFile={reloadFile}
        />
      ) : (
        ""
      )}
    </>
  );
}
