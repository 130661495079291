import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import TableHeader from "./TableHeader";
import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { UserContext } from "../contexts/UserContext";
import Moment from "react-moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";

export default function AllProjects() {
  const [search, setSearch] = useState("");
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const {
    isProjectAddOrUpdate,
    setIsProjectAddOrUpdate,
    draftId,
    setProjectId,
    setDraftId,
    setIsDraftAdd,
    isDraftAdd,
  } = useContext(ProjectContext);

  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  //setProjectId null when user press back button to main panel,
  // which helps to create new project instead to update selected project.
  useEffect(() => {
    if (location.pathname === "/main") {
      setDraftId(null);
    }
  }, [location.pathname, draftId]);

  // settingProject id in state as well as local storage so that we can persist our
  // state after refreshing in selected project components
  const navigate = useNavigate();
  const setDraftHandler = (draftId) => {
    setDraftId(draftId);
    console.log("Draft Id==>", draftId);
    navigate("/draft");
  };
  //loading all project list once only
  useEffect(() => {
    //fetching data from endpoint
    const getDrafts = async () => {
      try {
        setLoading(true);
        let response;
        response = await axios.get(`${process.env.REACT_APP_API_URL}/drafts`);

        if (user?.userData?.role === "Admin") {
          setProjects(response.data);
          setFilteredProjects(response.data);
        } else {
          let loginUser = user?.userData?.name;
          let filtredData = response?.data?.filter(
            (item) => item?.createdBy === loginUser
          );
          setProjects(filtredData);
          setFilteredProjects(filtredData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getDrafts();
  }, [isDraftAdd, user?.userData]);

  //multiple fields search based on search key
  useEffect(() => {
    const filters = projects.filter(
      (draft) =>
        JSON.stringify(draft).toLowerCase().indexOf(search.toLowerCase()) !== -1
    );

    setFilteredProjects(filters);
  }, [search]);

  const terminateDraft = async (draftId) => {
    try {
      const { value } = await Swal.fire({
        title: "Are you sure?",
        text: `You want to Delete this Draft`,
        icon: "warning",
        confirmButtonText: "Delete",
        confirmButtonColor: "red",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      });
      if (value) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/drafts/delete/${draftId}`)
          .then((res) => {
            Swal.fire({
              title: "Done!",
              text: "Draft has been Deleted successfully.",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
        //to recall projects
        setIsDraftAdd(!isDraftAdd);
      } else {
        Swal.fire({ text: "Draft is safe from Delete.", icon: "info" });
      }
    } catch (error) {
      toast.error("Somthing went wrong. Draft has not Deleted.");
    }
  };

  const columns = [
    
    {
      name: "Search Object",
      selector: (row) => row.searchObject,
      sortable: true,
    },
    {
      name: "Patent Number",
      selector: (row) => row.patentNumber,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row?.requestedDate,
      sortable: true,
      cell: (row) => <Moment format="DD/MM/YYYY">{row?.requestedDate}</Moment>,
    },
    {
      name: "Delievery Date",
      selector: (row) => row?.deliveryDate,
      sortable: true,
      cell: (row) => <Moment format="DD/MM/YYYY">{row?.deliveryDate}</Moment>,
    },
    {
      name: "Type Of Search",
      selector: (row) => row?.typeOfSearch,
      sortable: true,
      // cell: (row) => (
      //   <textarea style={{ fontSize: "10px" }}>{row.TypeOfSearch}</textarea>
      // ),
    },
    {
      name: "Technical Feild",
      selector: (row) => row?.technicalField,
      sortable: true,
      // cell: (row) => <Moment format="DD/MM/YYYY">{row?.technicalField}</Moment>,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn text-danger btn-sm"
            onClick={() => terminateDraft(row._id)}
          >
            <MdDeleteOutline size={20} />
          </button>
          &nbsp;
          <button
            type="button"
            className="btn text-primary btn-sm"
            onClick={() => setDraftHandler(row?._id)}
          >
            <MdModeEditOutline size={20} />
          </button>
        </>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        fontSize: "12px",
        fontWeight: "bolder",
        cursor: "pointer",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <DataTable
          columns={columns}
          data={filteredProjects}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="470px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            // <TableHeader props={{ setSearch, projects, selectedProjects }} />
            <div
              className="w-50 form-control me-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                type="search"
                placeholder="Quick Search"
                onChange={(e) => setSearch(e.target.value)}
                className=" w-100 inputFieldOut"
                style={{ border: "none" }}
              />
              <span style={{ textAlign: "center" }}>
                <AiOutlineSearch size={20} />
              </span>
            </div>
          }
          onRowClicked={(row) => setDraftHandler(row?._id)}
          striped
          customStyles={customStyles}
          responsive
          onSelectedRowsChange={(selectedRows) =>
            setSelectedProjects(selectedRows?.selectedRows)
          }
          progressPending={loading}
          progressComponent={
            <div className="d-flex align-items-center p-5">
              <div
                className="spinner-border text-primary"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="sr-only mt-5"></span>
              </div>
              <h5 className="color text-secondary ms-3">Loading Drafts...</h5>
            </div>
          }
        />
      </div>
    </>
  );
}
