import React from "react";
import effectual_Logo from "../../Assets/img/Effectual_Logo.png";

export default function Footer() {
  return (
    <>
      <footer className="pb-3">
        <div className="footer-top py-5 ld border-top border-bottom">
          <div className="container">
            <div className="row gy-4 justify-content-around">
              <div className="col-lg-5 col-md-12 footer-info">
                <a
                  href="/"
                  className="d-flex align-items-center text-decoration-none mb-4"
                >
                  <img src={effectual_Logo} alt="" width={100} />
                  <h2 className="ms-2">Effectual RMS</h2>
                </a>
                <p className="text-secondary fw-bold">
                  Effectual’s report management system centrally manages all
                  type of client projects along with the repository of search
                  reports and communication. The platform sends notification to
                  the stakeholders of a project whenever any new activity is
                  processed.
                </p>
                <div className="social-links mt-3 d-flex justify-content-around">
                  <a
                    href="https://twitter.com/effectualip?lang=en"
                    className="twitter"
                  >
                    {/* <i className="bi bi-twitter"></i> */}
                    <img
                      src="twitter.jpg"
                      alt="twitter"
                      style={{ width: "70%" }}
                    />
                  </a>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/EffectualServices"
                  >
                    <img src="fb.jpg" alt="fb" style={{ width: "70%" }} />
                  </a>
                  <a className="instagram" href="/">
                    <img src="ig.jpg" alt="ig" style={{ width: "70%" }} />
                  </a>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/effectual-services/"
                  >
                    <img
                      src="linkedin.jpg"
                      alt="linkedin"
                      style={{ width: "70%" }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 text-center text-md-start">
                <h4>Contact Us</h4>
                <p className="text-secondary fw-bold">
                  <br /> SDF A-05, NSEZ, Noida-Dadri Road, Noida Phase II,
                  Noida, UP 201305 <br />
                  <br />
                  <strong>Phone:</strong> India: +91-120-4522210,
                  +91-120-4522211
                  <br />
                  <strong>Email:</strong>info@effectualservices.com
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright pt-4 text-center theme3-color">
            Copyright @ 2016 - 2024{" "}
            <strong>
              <span>Effecutal Knowledge Services</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
}
