import React, { useEffect, useState, useRef } from "react";
import AddNewUser from "./AddNewUser";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";

export default function ManageUser() {
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState([]);
  const [status] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const target = useRef(null);
  const [updatedData, setUpdatedData] = useState({
    id: "",
    name: "",
    email: "",
    role: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [roleShow, setRoleShow] = useState(false);

  const getUserData = async () => {
    const EffectualUsers = ["Effectual Admin", "Manager", "Searcher"];
    const ClientUsers = ["Admin", "Patent Expert", "Technical Expert"];
    const loginUser = user?.userData?.role;

    //if return boolean true it means logged in user is Effectual user else it might be client user.
    if (EffectualUsers.indexOf(loginUser) !== -1) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/users/getEffectulalUsers`)
          .then((res) => res.json())
          .then((data) => {
            setUserData(data);
            
            setFilteredUsers(data);
          });
      } catch (error) {
        toast.error("Something went wrong in fetching users.");
      }
    }
    // now if user is not effectual side than it might be a client user.
    else if (ClientUsers.indexOf(loginUser) !== -1) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/users/getClientUsers`)
          .then((res) => res.json())
          .then((data) => {
            setUserData(data);
            console.log("filteredUsers",data);
            setFilteredUsers(data);
          });
      } catch (error) {
        toast.error("Something went wrong in fetching users.");
      }
    } else {
      toast.warning("You are neither an Effectual User nor a Client User.");
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleShow]);

  useEffect(() => {
    const filters = userData?.filter(
      (user) =>
        JSON.stringify(user).toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
    setFilteredUsers(filters);
  }, [search, userData]);

  const handleUsersDelete = async (id, name) => {
    const confirmation = window.confirm(`Are you sure to delete:  ${name} ?`);
    if (confirmation) {
      try {
        let res = await axios.put(
          `${process.env.REACT_APP_API_URL}/users/delete/${id}`,
          status
        );
        if (res) {
          getUserData();
        }
      } catch (error) {
        console.log(error);
      }
    }
    return;
  };

  // const ChangeRole = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     let res = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/users/update-role/${id}`,
  //       role
  //     );
  //     if (res) {
  //       toast.success("The role has been updated");
  //     }
  //     setIsLoading(false);
  //     setRoleShow(false);
  //   } catch (error) {
  //     console.log(error);
  //     setRoleShow(false);
  //   }
  // };

  //UPDATE USER

  const updateUser =async () => {
    try {
      setIsLoading(true);
      let res = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/update-role/${updatedData?.id}`,
        updatedData
      );
      if (res) {
        toast.success("The role has been updated");
      }
      setIsLoading(false);
      setRoleShow(false);
    } catch (error) {
      console.log(error);
      setRoleShow(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row pt-3 justify-content-between">
          <div className="col-md-3">
            <h3 className="text-center theme-color">Users List</h3>
          </div>
          <div className="col-md-4">
            <div
              className="form-control me-2 border border-primary "
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <input
                id="search"
                name="search"
                className="inputFieldOut w-100"
                type="search"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                style={{ border: "none" }}
              />
              <span>
                <AiOutlineSearch size={20} />
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <button
              type="button"
              className="btn btn-outline-secondary rounded-pill w-100"
              // data-bs-toggle="modal"
              // data-bs-target="#addUser"
              onClick={() => setReloadData(true)}
            >
              Add New User
            </button>
          </div>
        </div>
        <div className="row pt-3">
          <div>
            <div style={{ height: "67vh" }} className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead className="table-dark" data-sticky-header={true}>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Email Id</th>
                    <th>Role</th>
                    <th>Action</th>
                    <th>Edit Role</th>
                  </tr>
                </thead>
                <tbody className="fw-bold">
                  {filteredUsers
                    ?.filter((e) => {
                      return e.status === true;
                    })
                    ?.map((item, i) => (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                        <td className="text-center">
                          <button
                            ref={target}
                            type="button"
                            className="btn btn-outline-danger rounded-pill"
                            onClick={() =>
                              handleUsersDelete(item._id, item.name)
                            }
                          >
                            Remove
                          </button>
                          {/* {user?.userData?.role === "Admin" ? (
                            <button
                              ref={target}
                              type="button"
                              className="btn btn-outline-red rounded-pill ml-3"
                              onClick={() => {
                                const user = filteredUsers?.filter(
                                  (a) => a._id === item._id
                                );
                                console.log(user[0].role);
                                setRole({
                                  ...role,
                                  value: user[0].role,
                                  id: item._id,
                                });
                                setRoleShow(true);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#updateRole"
                            >
                              <AiOutlineEdit size={25} />
                            </button>
                          ) : (
                            ""
                          )} */}
                        </td>
                        <td>
                          <button
                            ref={target}
                            type="button"
                            className="btn btn-outline-red rounded-pill ml-3"
                            onClick={() => {
                              const user = filteredUsers?.filter(
                                (a) => a._id === item._id
                              );

                              setUpdatedData({
                                ...updatedData,
                                id: item?._id,
                                name:item.name,
                                email:item.email,
                                role:item.role
                              });
                              setRoleShow(true);
                            }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#updateRole"
                          >
                            <AiOutlineEdit size={25} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* start change role modal popup */}

        {roleShow && (
          <div
            // className="modal fade"
            style={{
              zIndex: 200,
              top: 30,
              position: "absolute",
              right: 0,
              left: 0,
            }}
            id="updateRole"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center theme-color">
                    Edit User
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setRoleShow(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-field mt-0 ">
                          <input
                            type="text"
                            className="form-control"
                            value={updatedData.name}
                            onChange={(e) =>
                              setUpdatedData({
                                ...updatedData,
                                name: e.target.value,
                              })
                            }
                          />
                          <label>User Name:</label>
                          <span className="d-none">Error : Field Required</span>
                        </div>
                        <div className="input-field mt-0 ">
                          <input
                            type="email"
                            className="form-control"
                            value={updatedData.email}
                            onChange={(e) =>
                              setUpdatedData({
                                ...updatedData,
                                email: e.target.value,
                              })
                            }
                          />
                          <label>User Email:</label>
                          <span className="d-none">Error : Field Required</span>
                        </div>
                        <div className="input-field mt-0 ">
                          <select
                            type="text"
                            className="form-control"
                            required
                            // value={filteredUsers.filter((a) => a._id === role.id)}
                            value={updatedData.role}
                            onChange={(e) =>
                              setUpdatedData({
                                ...updatedData,
                                role: e.target.value,
                              })
                            }
                          >
                          {
                            updatedData.role=='Admin'?(<>
                              <option value="" disabled selected></option>
                              <option value="Admin">Admin</option>
                              <option value="Patent Expert">Patent Expert</option>
                              <option value="Technical Expert">
                                Technical Expert
                              </option></>):(<>
                                <option value="Manager">Manager</option>
                            <option value="Searcher">Searcher</option>
                            <option value="Effectual Admin">
                              Effectual Admin
                            </option>
                                </>)
                          }
                           
                          </select>
                          <label>Role :</label>
                          <span className="d-none">Error : Field Required</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-secondary rounded-pill"
                    data-bs-dismiss="modal"
                    onClick={() => setRoleShow(false)}
                  >
                    Close
                  </button>
                  <div className="">
                    {isLoading && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    )}
                    <button
                      type="button"
                      disabled={isLoading}
                      variant="btn btn-success w-100"
                      className="btn theme-bg text-white rounded-pill"
                      // onClick={() => ChangeRole(role.id)}
                      onClick={() => updateUser()}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* end change role modal popup */}
      </div>

      {reloadData && (
        <AddNewUser
          setReloadData={setReloadData}
          reloadData={reloadData}
          getUserData={getUserData}
        />
      )}
    </>
  );
}
