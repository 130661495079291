import React, { useContext, useState } from "react";
import AddUserToProject from "./AddUserToProject";
import ProjectInfo from "./ProjectInfo";
import ReviewInformation from "./ReviewInformation";
import UploadFiles from "./UploadFiles";
import { ProjectContext } from "../contexts/ProjectContext";
import axios from "axios";
import { useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/inject-style";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function CreateProjectUsingDraft() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const {
    projectId,
    setIsProjectAddOrUpdate,
    draftId,
    setDraftId,
    isDraftAdd,
    setIsDraftAdd,
  } = useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");

  const initialValue = {
    SearchObject: "",
    TechnicalField: "",
    KnownPriorArt: "",
    ClaimsToBeSearched: "",
    RequirementForDelivery: "",
    RequirementDeliveryDate: "",
    PriorArtCuttOffDate: "",
    StandardRelated: "",
    SSONeeded: "",
    USIPRSpecial: "",
    ImportantClaims: "",
    UnimportantClaims: "",
    UsefulInformationForSearch: "",
    createdBy: user?.userData?.name,
    CreatedById: user?.userData?._id,
    techId: "",
  };
  const [formData, setFormData] = useState(initialValue);
  const [assignedUsers, setAssginedUsers] = useState([]);
  useEffect(() => {
    if (formData?.SearchObject === "")
      setError1("search object field required.");
    else setError1("");
    if (formData?.RequirementDeliveryDate === "")
      setError2("requirement delivery date required.");
    else setError2("");

    if (error1 || error2) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [formData, error1, error2]);

  const [fileNames, setFileNames] = useState([]);

  const [attachment, setAttachment] = useState({
    files: [],
    uploadedBy: user?.userData?.name,
    userRole: user?.userData?.role,
  });

  //getting project based on id

  //Draft----------------------------------------

  const getDraft = async (draftId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/drafts/${draftId}`
      );
      setFormData({
        ...formData,
        SearchObject: res?.data?.searchObject ? res?.data?.searchObject : "",
        TechnicalField: res?.data?.technicalField
          ? res?.data?.technicalField
          : "",
        KnownPriorArt: res?.data?.patentNumber ? res?.data?.patentNumber : "",
        ClaimsToBeSearched: res?.data?.typeOfSearch
          ? res?.data?.typeOfSearch
          : "",
        RequirementForDelivery: res?.data?.reqDelivery
          ? res?.data?.reqDelivery
          : "",
        RequirementDeliveryDate: res?.data?.deliveryDate
          ? res?.data?.deliveryDate
          : "",
        PriorArtCuttOffDate: res?.data?.priorArtDate
          ? res?.data?.priorArtDate
          : "",
        StandardRelated: res?.data?.standard ? res?.data?.standard : "",
        SSONeeded: res?.data?.sso ? res?.data?.sso : "",
        USIPRSpecial: res?.data?.usipr ? res?.data?.usipr : "",
        ImportantClaims: res?.data?.impClaim ? res?.data?.impClaim : "",
        UnimportantClaims: res?.data?.nonImpClaim ? res?.data?.nonImpClaim : "",
        UsefulInformationForSearch: res?.data?.info ? res?.data?.info : "",
        techId: res?.data?.techId ? res?.data?.techId : "",
      });
      setAttachment({
        ...attachment,
        files: res?.data?.files ? res?.data?.files :[],
      });
      setAssginedUsers(
        res?.data?.assignedUsers ? res?.data?.assignedUsers : []
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDraft(draftId);
  }, [draftId]);

  const FormTitles = [
    "Project Information",
    "Upload Files",
    "Add Users",
    "Review Information",
  ];

  const PageDisplay = () => {
    let returnvalue;
    switch (page) {
      case 0:
        returnvalue = (
          <ProjectInfo
            formData={formData}
            setFormData={setFormData}
            error1={error1}
            error2={error2}
          />
        );
        break;
      case 1:
        returnvalue = (
          <UploadFiles
            formData={formData}
            setFormData={setFormData}
            attachment={attachment}
            setAttachment={setAttachment}
            fileNames={fileNames}
            setFileNames={setFileNames}
          />
        );
        break;
      case 2:
        returnvalue = (
          <AddUserToProject
            assignedUsers={assignedUsers}
            setAssginedUsers={setAssginedUsers}
          />
        );
        break;
      case 3:
        returnvalue = (
          <ReviewInformation formData={formData} fileNames={fileNames} />
        );
        break;
      default:
        returnvalue = null;
        break;
    }
    return returnvalue;
  };
  //-------------------------Save asDraft---------------------------------

  const UpdateDraft = async () => {
    try {
      let newFormData = {
        ...formData,
        files: attachment?.files,
        assignedUsers: assignedUsers,
      };
      console.log("newform data", newFormData);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/drafts/update/${draftId}`,
        newFormData
      );
      toast.success("Draft Updated Sucessfully.");
      navigate("/main");
    } catch (err) {
      console.log(err);
      toast.success("Draft Not Updated!");
    } finally {
      setFormData(initialValue);
      setFileNames([]);
      setPage(0);
      setIsDraftAdd(!isDraftAdd);
    }
  };

  //----------------Handler for Project Creation ---------------
  const projectHandler = async () => {
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/projects/create`,
        formData
      );

      if (res?.data?.status === "success") {
        if (attachment?.files.length > 0) {
          // setAttachment({ ...attachment, projectId: res?.data?.projectId });
          await axios.post(`${process.env.REACT_APP_API_URL}/files/saveToDb`, {
            projectId: res?.data?.projectId,
            files: attachment?.files,
            role: attachment?.userRole,
            uploadedBy: attachment?.uploadedBy,
          });
          setAttachment({
            files: [],
            uploadedBy: user?.userData?.name,
            userRole: user?.userData?.role,
          });
        }

        if (assignedUsers.length > 0) {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/assigned/createUser`,
            {
              userId: assignedUsers,
              projectId: res?.data?.projectId,
            }
          );

          setAssginedUsers([]);
        }

        setIsProjectAddOrUpdate(true);
        Swal.fire({
          icon: "success",
          title: res?.data?.msg,
        });
        setTimeout(() => {
          document.getElementById("homeTabBtn").click();
        }, 1000);
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/drafts/delete/${draftId}`
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Project not created!",
          text: res?.data?.msg,
        });
      }
    } catch (error) {
      toast.error("something went wrong.");
    } finally {
      setIsLoading(false);
      setDraftId(null);
      navigate("/main");
      setIsDisabled(false);
      //clear FormData Completely after creating project
      setFormData(initialValue);
      setFileNames([]);
      setPage(0);
    }
  };

  return (
    <>
      <div className="container p-4 pt-1">
        <div className="row pt-2">
          <div
            className="col-12 col-md-8 progress p-0"
            style={{ marginTop: "7px", height: "2rem" }}
          >
            <div
              className="progress-bar theme-bg fw-bold fs-6"
              role="progressbar"
              style={{
                width:
                  page === 0
                    ? "25%"
                    : page === 1
                    ? "50%"
                    : page === 2
                    ? "75%"
                    : "100%",
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Step {page + 1}/4
            </div>
          </div>

          <div className="col-12 col-md-4 d-flex justify-content-end mt-1">
            <button
              type="button"
              className="btn btn-secondary rounded-pill w-50 me-3 form"
              onClick={() => setPage((current) => current - 1)}
              disabled={page === 0}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn-secondary rounded-pill w-50 me-3 form"
              style={{ background: "#3F2FC5" }}
              onClick={() => UpdateDraft()}
              disabled={page !== 3}
            >
              Update Draft
            </button>

            {isLoading && (
              <>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <span className="text-primary ms-2 mt-1">Please wait...</span>
              </>
            )}
            <button
              type="button"
              disabled={isDisabled}
              className="btn btn-success rounded-pill w-50 form"
              onClick={() => {
                if (page === FormTitles.length - 1) {
                  projectHandler();
                } else {
                  setPage((current) => current + 1);
                }
              }}
            >
              {page === FormTitles.length - 1 ? "Submit" : "Next"}{" "}
            </button>
          </div>
        </div>

        <div>{PageDisplay()}</div>
      </div>
    </>
  );
}
